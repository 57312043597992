import Define from '../constants/define'

export const saveClientCompanyWarehouseToLocal = (
  clientId: string,
  companyId: string,
  warehouseId: string,
  clientCodeNom: string,
  companyCodeNom: string,
  warehouseCodeNom: string
) => {
  localStorage.setItem(Define.CHOOSING_CLIENT, clientId || '')
  localStorage.setItem(Define.CHOOSING_COMPANY, companyId || '')
  localStorage.setItem(Define.CHOOSING_WAREHOUSE, warehouseId || '')
  localStorage.setItem(Define.CHOOSING_CLIENT_CODENOM, clientCodeNom || '')
  localStorage.setItem(Define.CHOOSING_COMPANY_CODENOM, companyCodeNom || '')
  localStorage.setItem(
    Define.CHOOSING_WAREHOUSE_CODENOM,
    warehouseCodeNom || ''
  )
}

export const getLocalClientCompanyWarehouse = () => {
  const localClientId = localStorage.getItem(Define.CHOOSING_CLIENT) || ''
  const localClientCodenom =
    localStorage.getItem(Define.CHOOSING_CLIENT_CODENOM) || ''
  const localCompanyId = localStorage.getItem(Define.CHOOSING_COMPANY) || ''
  const localWarehouseId = localStorage.getItem(Define.CHOOSING_WAREHOUSE) || ''
  const localCompanyNom =
    localStorage.getItem(Define.CHOOSING_COMPANY_CODENOM) || ''
  const localWarehouseNom =
    localStorage.getItem(Define.CHOOSING_WAREHOUSE_CODENOM) || ''
  return {
    localClientId,
    localClientCodenom,
    localCompanyId,
    localWarehouseId,
    localCompanyNom,
    localWarehouseNom,
  }
}
